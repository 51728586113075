import arWebsiteSettings from "../../../data/website-settings/ar.json";
import enWebsiteSettings from "../../../data/website-settings/en.json";
import all_flags from "../../../data/feature-flags/all.json";
import useLocalStorage from "@hooks/useLocalStorage";
import useCookieClient from "@hooks/useCookieClient";
export function websiteConfig(lang) {
  if (lang === "ar") {
    return arWebsiteSettings;
  }
  return enWebsiteSettings;
}
export function checkFeatureFlag(flag) {
  var _useLocalStorage = useLocalStorage(),
    getLocalStorage = _useLocalStorage.getLocalStorage;
  var _useCookieClient = useCookieClient(),
    getCookieClient = _useCookieClient.getCookieClient;
  if (__IS_CSR__ && window.location.href.includes(flag)) {
    return true;
  }
  if (__IS_CSR__) {
    if (getLocalStorage(flag)) {
      return true;
    }
  }
  if (__IS_CSR__ && getCookieClient(flag)) {
    return true;
  }
  return all_flags[flag] || false;
}
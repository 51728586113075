import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { useLang } from "@/common/Contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import { serverUrl, SHOW_DEBUG_INFO } from "@globalConfig";
import { canonicalize } from "@/utils/common";
import { dispatchFullLoading } from "@hooks/useFullLoading";
export var _objectToHash = function _objectToHash(hash) {
  var hashParams = [];
  for (var _i = 0, _Object$entries = Object.entries(hash); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    hashParams.push("".concat(key, "=").concat(value));
  }
  return hashParams.length > 0 ? "#" + hashParams.join("&") : "";
};
//Create Internal Link with params
export var _internal_create_link = function _internal_create_link() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var lang = arguments.length > 2 ? arguments[2] : undefined;
  var hash = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var langParam = lang !== "ar" ? "/en" : "";
  var urlParams = [];
  for (var _i2 = 0, _Object$entries2 = Object.entries(params); _i2 < _Object$entries2.length; _i2++) {
    var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
      key = _Object$entries2$_i[0],
      value = _Object$entries2$_i[1];
    if (value === null || value === undefined) continue;
    urlParams.push("".concat(key, "=").concat(value));
  }
  if (path.includes('?')) {
    var pathArray = path.split('?');
    path = pathArray[0];
    urlParams.push(pathArray[1]);
  }
  path = path.replace('/en/', '/').replace(/^\/en/, '/').replace(/\/$/, '');
  return "".concat(langParam).concat(path ? path : "/").concat(urlParams.length > 0 ? "?" + urlParams.join("&") : "").concat(_objectToHash(hash));
};
var useHelpers = function useHelpers() {
  var lang = useLang();
  var navigate = useNavigate();
  var lang2 = lang === "ar" ? "en" : "ar";
  var createUrl = function createUrl(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (!url) return "";
    if (url.startsWith('http')) {
      var newUrl = new URL(url);
      return newUrl.pathname + newUrl.search;
    }
    var uri = _internal_create_link(url, params, lang, hash);
    if (lang === 'en' && uri.startsWith('/en')) {
      uri = uri.replace('/en/en', '/en');
    }
    return uri;
  };
  var createUrlAbsolute = function createUrlAbsolute(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return serverUrl + createUrl(url, params);
  };
  var navigateTo = function navigateTo(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return navigate(createUrl(url, params));
  };
  var navigateToHard = function navigateToHard(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    dispatchFullLoading(true);
    if (SHOW_DEBUG_INFO) {
      if (confirm("[QA ONLY] Are you sure you want to navigate to " + createUrl(url, params) + "?")) {
        window.scrollTo({
          top: 0
        });
        window.location.href = createUrl(url, params);
      } else {
        dispatchFullLoading(false);
      }
      return;
    }
    dispatchFullLoading(true);
    window.scrollTo({
      top: 0
    });
    window.location.href = createUrl(url, params);
  };
  var createUrl2 = function createUrl2(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return canonicalize(_internal_create_link(url, params, lang2));
  };
  var getCurrentUrl = function getCurrentUrl() {
    if (__IS_SSR__) return "";
    return window.location.href;
  };
  return {
    createUrl: createUrl,
    createUrlAbsolute: createUrlAbsolute,
    createUrl2: createUrl2,
    navigateTo: navigateTo,
    navigateToHard: navigateToHard,
    getCurrentUrl: getCurrentUrl
  };
};
export default useHelpers;
import {SHOW_DEBUG_INFO} from "@globalConfig";
import CustomToast from "@componentsShared/CustomToast";
import {handleGtmEvent} from "@/common/events/gtmEvents";
import {handleFreshworkEvent} from "@/common/events/freshworkEvents";
import {checkFeatureFlag} from "@/common/config/websiteConfig";

if (__IS_CSR__) {
    window.lastEvent = null;
}

export const trackEvents = (event, data) => {
  if (window.lastEvent === event) {
    return;
  }
  window.lastEvent = event;
  setTimeout(() => {
    window.lastEvent = null;
  }, 500);

    handleGtmEvent(event, data); // GTM
    checkFeatureFlag("FF_ENABLE_FRESHWORKS_EVENT") && handleFreshworkEvent(event, data) //FreshWorks
};


export function showToastMsg(params, event, title = "dataLayer") {
    SHOW_DEBUG_INFO && window?.toast(
        (t) => {
            const paramsCopy = {...params};
            // delete user_country,language
            delete paramsCopy.user_country;
            delete paramsCopy.language;
            // loop through the params and substring to max 50 char
            for (const key in paramsCopy) {
                if (paramsCopy.hasOwnProperty(key)) {
                    const element = paramsCopy[key];
                    if (typeof element === "string") {
                        paramsCopy[key] = element.substring(0, 20);
                    }
                }
            }
            return (
                <div style={{direction: "ltr"}}>
                    <CustomToast
                        title={title + " " + event}
                        message={JSON.stringify(paramsCopy, null, 2)}
                        onClose={() => toast.dismiss(t.id)}
                    />
                </div>
            );
        },
        {
            duration: 100000000,
            position: "bottom-left",
            style: {
                direction: "ltr",
                border: "0",
                padding: "0",
                color: "transparent",
                margin: "0",
            },
        }
    );
}

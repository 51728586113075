import {useEffect, useState} from 'react';
import useLocalStorage from "@hooks/useLocalStorage";

export const CSR_LOADED_EVENT = 'csrFinishLoadingEvent';
const addBodyClass = () => {
    if (!document.body.classList.contains("isFirstInteraction")) {
        document.body.classList.add("isFirstInteraction");
    }
};
export const external_first_interaction = () => {
    console.log('external_first_interaction');
    setTimeout(() => {
        window.dispatchEvent(new CustomEvent(CSR_LOADED_EVENT, {detail: null}));
        addBodyClass();
        window.tryToRunDelayedAction();
    }, 100);
}

const useIsFirstInteraction = () => {
    const [isFirstInteraction, setIsFirstInteraction] = useState(false);
    const {setLocalStorage, getLocalStorage} = useLocalStorage();
    const finalizeFirstInteraction = () => {
        window.removeEventListener(CSR_LOADED_EVENT, finalizeFirstInteraction);
        if (!isFirstInteraction) {
            setIsFirstInteraction(true);
            addBodyClass();
            window.isFirstInteraction = true;
            setLocalStorage('isFirstInteraction', 'true');
        }
    };

    useEffect(() => {
        if (window.isFirstInteraction || getLocalStorage('isFirstInteraction') === 'true'){
            setIsFirstInteraction(true);
            return;
        }

        window.addEventListener(CSR_LOADED_EVENT, finalizeFirstInteraction);

        return () => {
            window.removeEventListener(CSR_LOADED_EVENT, finalizeFirstInteraction);
            finalizeFirstInteraction();
        };
    }, []);

    return isFirstInteraction;
};

export default useIsFirstInteraction;
